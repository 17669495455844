.cookie-notice-container {
  font-family: "Open Sans", sans-serif;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.cookie-notice-bottom {
  background-color: #232e46;
  position: fixed;
  min-height: 90px;
  display: flex;
  bottom: 0;
  width: 100%;
  z-index: 99999;
  justify-content: center;

  .cookie-notice-contents {
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a, a:focus, a:visited {
      color: #fff !important;
      text-decoration: underline !important;
      font-family: "Open Sans", sans-serif !important;
      font-size: 12px !important;
    }

    .cookie-notice-text {
      padding: 10px;
      font-size: 12px;
      font-weight: normal;
      line-height: 17px;
      color: #fff;
      flex-basis: 60%;
    }

    .cookie-notice-buttons {
      padding: 10px;
      display: flex;

      .options-btn {
        padding: 12px 32px;
        font-weight: 600;
        min-width: auto;
      }
    }
  }
}

.options-btn {
  text-transform: uppercase;
  padding: 7px 22px;
  margin: 4px;
  border: 1px solid #d7dae2;
  border-radius: 4px;
  outline: none !important;
  font-size: 13px;
  letter-spacing: 0;
}

.options-btn:hover {
  cursor: pointer;
  opacity: 0.8;
}

.dark-btn {
  background-color: #232e46;
  color: #fff;
}

.light-btn {
  background-color: #fff;
  color: #232e46;
}

.cookie-preference-form-area {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 99999;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);

  .cookie-preference-form {
    display: flex;
    flex-direction: column;
    background: #ffffff 0 0 no-repeat padding-box;
    box-shadow: 0 2px 6px #0000004d;
    border-radius: 4px;
    padding: 24px;
    width: 500px;
    margin: 10px;
    max-height: calc(100vh - 80px);

    .cookie-preference-form__title {
      position: relative;
      text-align: left;
      font-size:18px;
      font-weight: 600;
      color: #232e46;
    }

    .close {
      cursor: pointer;

      &:after, &:before {
        content: "";
        height: 14px;
        width: 14px;
        border-top: 2px solid #AFB0B6;
        position: absolute;
        top: -6px;
        right: -14px;
        transform: rotate(-45deg);
      }

      &:before {
        right: -4px;
        transform: rotate(45deg);
      }

      &:hover,
      &:focus {
        &:before,
        &:after {
          border-color: #da3737;
        }
      }
    }

    .cookie-preference-form__body {
      flex-grow: 1;
      flex-shrink: 1;
      overflow-y: auto;
      flex-basis: 100%;
      font-weight: normal;
    }

    .cookie-form-desc {
      margin-top: 32px;
      text-align: left;
      font-size: 12px;
      line-height: 20px;
      color: #232e46;

      a, a:focus, a:visited {
        color: #232e46 !important;
        text-decoration: underline !important;
        font-family: "Open Sans", sans-serif !important;
        font-size: 12px !important;
      }
    }

    .cookie-options {
      margin-top: 21px;

      .cookie-row {
        outline: none;
        margin: 4px 0;

        .cookie-card-btn {
          display: block;
          outline: inherit;

          .cookie-option-card {
            padding: 17px 16px;
            background-color: #F5F6FA;
            display: flex;
            outline: inherit;
          }

          .cookie-chevron {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            padding: 0;
            border: none;
            background-color: transparent;
            margin-right: 5px;

            &:focus {
              outline: thin dotted #232e46;
            }
          }

          .cookie-chevron::after {
            background-image: url(/frontend/images/chevron_down.svg);
            content: "";
            display: flex;
            width: 8px;
            height: 5px;
            top: 50%;
            bottom: 50%;
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }

          .cookie-option-desc {
            text-align: left;
            font-size: 12px;
            letter-spacing: 0;
            color: #232e46;
            cursor: pointer;
          }
        }

        .accordion__panel {
          padding: 0 16px;
          background-color: #F5F6FA;
          text-align: left;
          font-size: 12px;
          font-family: "Open Sans", sans-serif !important;
          line-height: 20px;
          color: #232e46;
          visibility: hidden;
          height: 0;
          //transition: height 0.2s ease-in-out, visibility 0.1s ease-in-out 0.2s, padding 0.1s ease-in-out 0.2s;

          p {
            font-family: "Open Sans", sans-serif !important;
            font-size: 12px !important;
            font-weight: normal;
            color: #232e46;
            line-height: 20px;
            display: block;
          }
        }

        .accordion__panel.active {
          visibility: visible;
          height: auto;
          padding: 17px 16px;
        }
      }
    }

    .cookie-options-buttons {
      margin-top: 26px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}

.debroome-switch.ml-auto {
  margin-left: auto !important;
}

.debroome-switch {
  display: inline-block;
  width: 32px;
  height: 17px;
  border-radius: 20px;
  vertical-align: middle;
  overflow: hidden;
  position: relative;
  transition: all .2s ease-out;

  &__input {
    position: absolute;
    width: 100% !important;
    height: 100%;
    margin: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1;

    &:checked ~ .debroome-switch__label {
      background-color: #72AA87;

      &:after {
        left: 16px;
      }
    }

    &:disabled {
      cursor: not-allowed;

      & ~ .debroome-switch__label {
        opacity: 0.7;
      }
    }
  }

  &__label {
    background-color: #D3D5DA;
    position: relative;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    transition: all .2s ease-out;
    display: block;

    &:after {
      content: "";
      width: 15px;
      height: 15px;
      background-color: #fff;
      border-radius: 15px;
      position: absolute;
      margin: 0;
      padding: 0;
      top: 1px;
      left: 1px;
      transition: all .2s ease-out;
    }
  }

  &:focus-within {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.65);
  }
}

#cookie_notice {
  .options-btn:focus {
    cursor: pointer;
    opacity: .8;
  }

  .cookie-notice-buttons .dark-btn:focus {
    background-color: #fff;
    color: #232e46;
  }

  .light-btn:focus {
    background-color: #232e46;
    color: #fff;
  }
}

@media (max-width: 992px) {
  .cookie-notice-bottom .cookie-notice-contents {
    flex-direction: column;
  }

  .cookie-notice-bottom .cookie-notice-contents .cookie-notice-buttons {
    flex-wrap: wrap;
  }
}

@media (max-width: 550px) {
  .cookie-notice-bottom .cookie-notice-contents {
    width: auto;
  }

  .cookie-preference-form-area .cookie-preference-form {
    width: 100%;
  }

  .options-btn {
    flex: 1;
  }
}
