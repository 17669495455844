.shop {
  &__multiple-card-row {
    display: inline-flex;
  }

  &__single-card-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__card {
    padding: 20px;
    box-shadow: 0 0 0 !important;
    border-radius: 5px !important;
    margin-right: 10px;

    .card__title {
      text-align: left;
      font-family: $fontSemiBold;
      font-size: 14px;
      line-height: 17px;
    }

    .card__content {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;

      &--space-between {
        justify-content: space-evenly;
        margin: 18px 20px;
        width: auto !important;
      }

      .spendable-coins {
        display: flex;
        flex-direction: row;
        position: relative;
        font-size: 35px;
        font-weight: 600;

        .glimmer-icon {
          margin-top: -3px;
        }

        .animated-container {
          letter-spacing: -1px;
        }
      }

      .all-time-coins {
        font-size: 35px;
        color: #707070;
      }
    }
  }

  &__price {
    font-size: 32px;
    font-weight: 400;
  }

  &__currency {
    font-size: 14px;
    margin-left: 5px;
    color: #00000080;
  }

  .card__spendable-coins {
    width: 433px;
    height: 122px;

    .card__content {
      margin-top: 17px;
    }

    .content_right {
      display: flex;
      flex-direction: row;

      .shop__currency {
        margin-top: 24px;
      }
    }
  }

  .card__all-time-coins {
    width: 328px;
    height: 122px;

    .card__content {
      margin-top: 17px;
    }
  }

  .card__purchasable-item {
    width: 774px;
    height: 150px;
    margin-top: 15px;
    transition: 300ms linear;

    &:hover {
      transform: scale(1.01);
    }

    .item-info {
      display: flex;
      flex-direction: row;

      .item__image {
        width: 160px;
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        background-color: rgb(var(--primaryRGB), 0.27);
        border-radius: 5px;

        img {
          max-width: 100px;
        }
      }

      .item__image-no-bottom {
        align-items: flex-end;
      }

      .item__description {
        display: flex;
        flex-direction: column;
        margin-left: 21px;
        text-align: left;

        .title {
          max-width: 327px;
        }

        .description {
          font-size: 14px;
          max-width: 250px;
        }
      }
    }

    .purchase-info {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      height: 110px;
      justify-content: space-between;
    }

    .purchased_done {
      justify-content: center;
      margin-right: 25px;
    }

    button {
      width: 118px;
    }
  }
}

.spendable-coins-footer-widget {
  display: none;
}

.purchase-modal {
  top: 13vh;

  .modal-dialog {
    display: flex;
    justify-content: center;
  }

  .modal-content {
    width: 386px;
  }

  &.fade {
    .modal-dialog {
      transform: translate3d(0, 15vh, 0);
    }
  }

  &.show {
    .modal-dialog {
      transform: translate3d(0, 0, 0);
      transition: transform 0.5s ease-out;
    }
  }

  .item__info {
    display: flex;
    flex-direction: column;

    .item__image {
      width: 352px;
      height: 238px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      background-color: rgb(var(--primaryRGB), 0.27);
      border-radius: 5px;

      img {
        max-width: 320px;
      }
    }

    .item__image-no-bottom {
      align-items: flex-end;
    }

    .item__purchase-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .item__description {
      display: flex;
      flex-direction: column;
      text-align: left;
      margin-top: 30px;

      .title {
        font-size: 14px;
        font-weight: bold;
        max-width: 246px;
      }

      .description {
        font-size: 14px;
        max-width: 246px;
      }

      .full-width {
        max-width: 339px !important;
      }
    }
  }

  .item__actions {
    display: flex;
    justify-content: center;
    margin-top: 22px;

    button {
      width: 120px;
    }

    .btn-buy {
      margin-left: 14px;
    }
  }
}

.spendable-coins-mobile-widget {
  display: none;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 11px 15px 11px 14px;
  box-shadow: 0 0 15px #8f8f8f;
  z-index: 1060;

  .title {
    max-width: 115px;
    text-align: left;
    font-weight: 600;
    font-size: 14px;
  }

  .coins-info {
    width: 110px;
    display: flex;

    .glimmer-icon {
      margin-top: 10px;
    }

    .spendable-coins {
      font-size: 32px;
    }

    .currency {
      font-size: 14px;
      margin-left: 2px;
      margin-top: 20px;
      color: #00000080;
    }
  }
}

.empty-space {
  height: 80px;
}

.purchase-modal-mobile {
  top: 0;
  padding: 0 !important;

  .modal-close {
    text-align: right;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .modal-dialog {
    display: flex;
    justify-content: center;
    max-width: 100%;
    height: 100%;
    margin: 0;
  }

  .modal-content {
    width: 100%;
    align-items: center;
    border: none;
    border-radius: unset;
  }

  .modal-body {
    width: 100%;
  }

  &.fade {
    .modal-dialog {
      transform: translate3d(0, 15vh, 0);
    }
  }

  &.show {
    .modal-dialog {
      transform: translate3d(0, 0, 0);
      transition: transform 0.5s ease-out;
    }
  }

  .item__info {
    display: flex;
    flex-direction: column;
    align-items: center;

    .item__image {
      width: 100%;
      height: auto;
      padding: 25px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      background-color: rgb(var(--primaryRGB), 0.27);
      border-radius: 5px;

      img {
        max-width: 280px;
      }
    }

    .item__purchased-done {
      width: 120%;
      height: 350px;
      margin-top: -17px;
    }

    .item__image-no-bottom {
      align-items: flex-end;
      padding-bottom: 0;
    }

    .item__purchase-info {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .item__price {
        text-align: center;
      }
    }

    .item__description {
      display: flex;
      flex-direction: column;
      text-align: left;
      margin-top: 30px;

      .title {
        font-size: 14px;
        font-weight: bold;
        max-width: 246px;
      }

      .description {
        font-size: 14px;
        max-width: 246px;
      }

      .full-width {
        max-width: 90% !important;
      }
    }
  }

  .item__actions {
    display: flex;
    justify-content: flex-start;
    margin-top: 22px;

    .btn-cancel {
      width: 30%;
      min-width: 120px;
    }

    .btn-buy {
      width: 100%;
      margin-left: 10px;
    }

    .btn-ok {
      width: 100%;
      margin-top: 40px;
    }
  }

  .swiper {
    width: 100%;
    height: 100%;
    margin-top: 10px;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85px;
    height: 50px;
    background-color: rgb(var(--primaryRGB), 0.27);
    border-radius: 5px;
    padding: 0;

    img {
      display: block;
      object-fit: cover;
      max-width: 60px;
      max-height: 45px;
    }

    .image-no-bottom {
      margin-top: 10px;
    }
  }

  .swiper-slide-active {
    background-color: rgb(var(--primaryRGB), 0.5);
  }
}

.modal-top {
  z-index: 1200;
}

//Shop prototype
@media only screen and (max-width: 767px) {
  .spendable-coins-mobile-widget {
    display: inline-flex;
  }

  .card__purchasable-item .card__content {
    flex-direction: column;
    justify-content: initial;
    align-items: stretch;
  }

  .shop {
    &__card {
      margin-bottom: 10px;
    }

    &__multiple-card-row {
      display: flex;
      flex-direction: column;
    }

    .card__spendable-coins {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .card__all-time-coins {
      width: 100%;
      height: 110px;

      .card__content {
        margin-top: 8px;

        .content_right {
          margin-top: -5px;
        }
      }
    }

    .card__purchasable-item {
      width: 100%;
      margin-top: 0;
      height: 100%;

      .item-info {
        flex-direction: column;

        .item__image {
          width: 100%;
          height: 154px;

          img {
            max-width: 140px;
          }
        }

        .item__description {
          margin-top: 10px;
          margin-left: 0;

          .description {
            max-width: 100%;
            margin-top: 3px;
          }
        }
      }

      .purchase-info {
        flex-direction: row;
        justify-content: end;
        height: auto;
      }

      .purchased_done {
        margin-right: 0;
      }

      button {
        width: 70px;
        padding: 11px;
      }
    }
  }

  .purchase-info {
    .price-info {
      margin-right: 10px;
      margin-bottom: -5px;
    }
  }

  .shop__card {
    .card__content {
      .all-time-coins {
        font-size: 40px;
      }

      .spendable-coins {
        font-size: 40px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .shop {
    &__card {
      margin-bottom: 10px;
    }

    &__multiple-card-row {
      display: flex;
      flex-direction: column;
    }

    .card__spendable-coins {
      width: 100%;
    }

    .card__all-time-coins {
      width: 100%;
    }

    .card__purchasable-item {
      width: 100%;
      margin-top: 0;
    }
  }
}

@media screen and (max-height: 700px) {
  .purchase-modal {
    top: 0;
  }
}
