//For small screens
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .statistics__wrapper {
    width: 100%;
  }

  .teacher.teacher__margin-bottom-0 {
    margin-bottom: 0px !important;
  }

  .feedback__text {
    width: 100%;
  }

  .teacher.teacher__results-wrapper {
    padding: 10px;
  }

  .teacher.teacher__margin-bottom-0 {
    margin-bottom: 0px !important;
  }

  .statistics__button-wrapper a {
    width: 100%;
    margin-bottom: 10px;
  }

  .level-selection-top {
    margin-top: 10px;
  }

  .draggable-wrapper {
    height: auto !important;

    .drag-drop__item {
      width: 100%;
    }
  }

  .profile-card {
    margin-bottom: 25px;
  }

  .level-select {
    flex-flow: wrap;

    button.btn.btn-primary.btn-md {
      width: 100%;
      margin-top: 10px;
    }

    &__level-name {
      font-size: 18px;
    }

    &__level-status {
      width: auto;
      height: auto;
    }
  }

  .level-selection-img-wrapper img {
    width: 100%;
  }

  .multiple-answer__item {
    margin-bottom: 5px;
  }

  .card__img-wrp {
    height: 220px;
  }

  .teacher__bubble {
    width: 100%;
  }

  .learning_content {
    width: 100%;
    margin: 0;
  }

  .stat-overview-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &__upper-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }

  .player-status-card {
    width: 100% !important;
    margin-bottom: 15px !important;
  }

  .game-players-count-card {
    width: 100% !important;
  }

  .room-achievement-status-wrapper {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .achievements-status-card {
    width: 100% !important;
  }

  .players-achievements-card {
    padding: 18px 20px;

    &__heading-container {
      display: flex;
      width: 100%;
    }

    &__achievements-container {
      display: flex;
      width: 100%;
      padding: 15px 0px 10px 15px;
      justify-content: space-between;
    }

    &__achievement-badge {
      padding-left: 10px;
      padding-right: 10px;
      margin-right: 0px !important;

      &:nth-child(3n-2) {
        display: flex;
        justify-self: baseline;
        padding-left: 0px !important;
      }

      &:nth-child(3n) {
        display: flex;
        justify-self: end;
        padding-right: 0px !important;
      }
    }

    &__title {
      text-align: left;
      font: normal normal 600 14px/17px Open Sans;
      flex: 1;
    }
  }

  .stat-card {
    &__content {
      &--margin-y {
        margin: 18px 0px !important;
      }

      &--space-between {
        display: grid !important;
        grid-template-columns: repeat(3, 1fr) !important;
        grid-row-gap: 25px;
      }
    }
  }
}


//For MD screens
@media (max-width: 767px) {
  .login {
    &__help-wrapper {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      justify-content: center;
    }

    &__forgot-password-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__forgot-password-group {
      position: relative;
      display: flex;
      flex-direction: column;
    }
  }

  .drag-drop__column {
    flex: auto;
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .profile-card .feedback-message {
    position: static;
    transform: translateY(0);
    max-width: initial;
    text-align: left;
    margin: 25px -40px -25px;
    padding: 20px 16px;

    &__text {
      font-size: 13px;
    }
  }

  .show-learning-content {
    top: 87px !important;
  }
}

@media only screen and (max-width: 900px) {
  .error-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1500px;
    padding: 40px;
  }

  .error-content .links,
  .error-content .text {
    margin-top: 40px;
  }

  .error-content h1 {
    font-size: 50px;
  }

  .error-content p {
    font-size: 18px;
  }

  .error-content a {
    font-size: 18px;
  }
}

@media (max-width: 991px) {
  .login {
    &__forgot-password-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__forgot-password-group {
      position: relative;
      display: flex;
      flex-direction: column;
    }
  }

  .level-select {
    &__wrapper {
      flex: auto;
      max-width: 100%;
      margin-top: 25px;
    }
  }

  .show-learning-content {
    top: 80px;
  }

  .teacher.teacher--alt {
    margin-top: 25px;
  }
}

@media only screen and (max-width: 768px) {
  .teacher {
    &__login-wrapper {
      width: auto;
      max-width: 400px;
      margin-left: 0px;
    }

    &__avatar-wrapper {
      padding-left: 0px;
      display: block;
      flex: 0;
      width: auto;
    }
  }

  .login {
    &__text-field {
      width: 100%;
      max-width: 400px;
    }

    &__button {
      width: 100%;
      max-width: 400px;
    }

    &__forgot-password-group {
      width: 100%;
      max-width: 400px;
    }

    &__inputs-group {
      width: 100%;
      max-width: 400px;
    }
  }

  .stat-overview-container {
    display: block;
    max-width: 540px;
    margin-right: auto;
    margin-left: auto;

    &__upper-wrapper {
      flex-direction: column;
      row-gap: 0.5rem;
    }
  }

  .player-status-card {
    width: 100%;

    &__player-stat-wrapper {
      flex-direction: column;
      row-gap: 5px;
    }

    &__coins {
      margin-left: 0px;
    }
  }

  .game-players-count-card {
    width: 100%;
  }

  .achievements-status-card {
    width: 100%;
  }

  .players-achievements-card {
    &__achievement-badge {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      margin-right: 0px !important;

      &:nth-child(1) {
        margin-right: 0px;
      }

      &:nth-child(2) {
        margin-right: 0px;
      }

      &:nth-child(3) {
        margin-right: 0px;
      }

      &:nth-child(4) {
        margin-right: 0px;
      }

      &:nth-child(5) {
        margin-right: 0px;
      }
    }
  }

  .stat-card {
    &__content {
      &--space-between {
        display: grid !important;
        grid-template-columns: repeat(3, 1fr) !important;
        grid-row-gap: 25px;
      }
    }
  }

  .pb-sidebar {
    transition: left 1s;
    position: fixed;
    left: 100%;
    width: 270px;
    z-index: 5;
    top: 12%;
    border-radius: 5px;
    background-color: #FFFFFF;

    &__wrapper {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      flex: unset;
      max-width: unset;
      padding-right: unset;
      padding-left: unset;
    }

    .profile-card__overflow-fix {
      background-color: rgba(var(--primaryRGB), 0.1);
    }

    &-animate {
      left: calc(50% - 135px);
    }

    &-animate-done {
      left: 100%;
      transform: none;
      visibility: visible;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1200px) {
  .pb-sidebar {
    &-animate {
      left: calc(50% - 135px);
    }

    &-animate-done {
      left: 100%;
      transform: none;
      visibility: visible;
    }
  }
}

//For XL screens
@media (max-width: 1199px) {
  .login {
    &__forgot-password-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__forgot-password-group {
      position: relative;
      display: flex;
      flex-direction: column;
    }
  }

  .overview {
    &__wrapper {
      display: flex;
      flex-direction: column;
    }

    &__rooms {
      max-width: 100%;
    }

    &__profile {
      max-width: 100%;
    }
  }
}

@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1200px;
  }
  .badge {
    display: block;
    white-space: pre-wrap;
  }
  .profile-card {
    padding: 50px 25px 27px 25px;

    &__learning-badge-inner {
      grid-template-columns: repeat(3, 1fr);
    }

    .empty-badges {
      display: none;
    }
  }

  .pb-sidebar {
    transition: left 1s, transform 1s;
  }
}

//For XXL screens
@media (min-width: 1600px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1440px;
  }
  .card--learning {
    min-height: 320px;
  }
  .card__img-wrp {
    height: 220px;

    &.wh-image {
      height: 251px;
    }
  }

  .badge {
    display: block;
    white-space: nowrap;
  }

  .statistics__wrapper {
    max-width: 900px;
    width: 100%;
  }
}

@media (max-width: 1600px) {
  .login {
    &__forgot-password-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__forgot-password-group {
      position: relative;
      display: flex;
      flex-direction: column;
    }
  }
}

@media only screen and (min-device-width: 1600px) and (max-device-width: 1919px) {
  .profile-card {
  }
}

//For XXXL screens
@media (min-width: 1920px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1760px;
  }
  .card--learning {
    min-height: 400px;
  }
  .card__img-wrp {
    height: 330px;

    &.wh-image {
      height: 361px;

      &.mqo {
        height: 330px;
      }

    }
  }

  .statistics__wrapper {
    max-width: 1000px;
    width: 100%;
  }
}

////////////////////////////////////////////////////////
//// Final screen profile card animations
///////////////////////////////////////////////////////
@media only screen and (max-device-width: 1250px) {
  .pb-sidebar {
  }
}

@media only screen and (min-device-width: 1200px) and (max-device-width: 1500px) {
  .pb-sidebar {
    transition: left 1s, transform 1s;
    &-animate {
      left: calc(50% - 135px);
      transform: unset;
    }
    &-animate-done {
      left: 100%;
    }
  }
}

@media only screen and (min-device-width: 1500px) and (max-device-width: 1599px) {
  .pb-sidebar {
    transition: left 1s, transform 1s;
    &-animate {
      left: calc(50% - 135px);
      transform: unset;
    }
    &-animate-done {
      left: 100%;
    }
  }
}

@media only screen and (min-device-width: 1600px) and (max-device-width: 1799px) {
  .pb-sidebar {
    right: -24%;

    &-animate {
      left: calc(50% - 135px);
    }

    &-animate-done {
    }
  }
}

@media only screen and (min-device-width: 1800px) {
  .pb-sidebar {
    &-animate {
      left: calc(50% - 135px);
    }

    &-animate-done {
      left: 75%;
    }
  }
}

@media only screen and (max-device-width: 1600px) {
  .diamonds__explode {
    left: 0;
    top: -76px;
  }
}

@media only screen and (min-device-width: 1700px) {
  .diamonds__explode {
    left: 35px;
  }
}

@media only screen and (min-device-width: 1920px) {
  .profile-card {
    &__learning-badge-inner {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 10px 25px;
    }

    .empty-badges {
      display: block;
    }
  }

  .pb-sidebar {
  }

  .pb-sidebar {
    &-animate {
      left: calc(50% - 135px);
    }

    &-animate-done {
    }
  }
}
